import { template as template_f73d8cd320b24c5b8f39bc40bed55207 } from "@ember/template-compiler";
const FKLabel = template_f73d8cd320b24c5b8f39bc40bed55207(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
