import { template as template_7860a509e01e41a6bed4d2f9ae3bcc06 } from "@ember/template-compiler";
const WelcomeHeader = template_7860a509e01e41a6bed4d2f9ae3bcc06(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
