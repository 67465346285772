import { template as template_85f5a89bb97d423980cad5e2d403f07f } from "@ember/template-compiler";
const FKText = template_85f5a89bb97d423980cad5e2d403f07f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
